.Embed-Container{
  height: 1000px;
  width: 100%;
}
#header {
  /* width: 20vw; */
  height: auto;
}
#header .pro-sidebar {
  height: 100%;
  padding-bottom: 20px;
}
#header .content {
  background-color: rgb(250, 251, 252);
}
#header .pro-sidebar {
  width: 200%;
  min-width: 150%;
  background-color: rgb(250, 251, 252);
}
#header .pro-sidebar.collapsed {
  width: 10%;
  min-width: 20%;
  background-color: rgb(250, 251, 252);
}
#header .pro-sidebar-inner {
  background-color: rgb(249, 250, 251);
  transition: all ease 200ms;
  padding-top: 0px;
  padding-bottom: 0px;
  overflow: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
  background-color: rgb(249, 250, 251);
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: rgb(250, 251, 252);
}

.pro-sidebar .pro-menu {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 2px;
  margin-left: 37px;
  transition: all ease 200ms;
  border-left-style: solid;
  border-left-color: rgb(59, 216, 244);
  background-color: rgb(249, 251, 249);
}

/* #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: rgb(135, 129, 129);
  margin: 0px 0px;
  font-weight: 400;
  background-color: rgb(249, 250, 251);
  font-family: system-ui;
  font-size: 0.7;
  transition: all ease 200ms;
} */

#header .pro-sidebar-inner .pro-sub-menu .pro-inner-list-item {
  padding-left: 0px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  border-radius: 3px;
  font-size: 1.2rem;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  color: rgb(135, 129, 129);
}


.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  color: #6a6868 !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: #6a686880 !important;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background: #219efb42;
  color: #219efb;
  /* border-radius: 0 25px 25px 0; */
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
    background-color: rgb(249, 250, 251);
  }
}

.chonky-chonkyRoot{
    border: none !important;
}


.MuiTableCell-root {
    border-right: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    border-left: 1px solid #e2e2e2;
}

.rowHeight55px {
    height: 55px;
}

@media (max-width: 600px) {
    .card-logo {
        display: none;
    }
}

@media (min-width: 601px) and (max-width: 1200px) {
    .body {
        zoom: 0.75;
    }

    .card-logo {
        display: none;
    }

    .map-list {
        width: 300px;
    }
}

@media (min-width: 1201px) {
    .map-list {
        width: 400px;
    }

    .body {
        zoom: 0.75;
    }

    
}

.map-container {
    display: grid;
    place-items: center;
    height: 400px;
    /* You can adjust the height as needed */
    width: 400px;
}

.react-flow_viewport .react-flow_container{
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    line-height: 1.5714285714285714;
    font-size: 0.875rem;
    font-family: Public Sans,sans-serif;
    font-weight: 400;
    text-align: left;
    border-collapse: collapse;
    border-spacing: 0;
    color: #212B36;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    pointer-events: none;
    transform-origin: 0 0;
    z-index: 2;
    transform: translate(100px, 59px) scale(0.5);
}
.react-flow__container {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    padding-left: inherit;
}